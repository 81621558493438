<template>
  <section class="page__block join-club">
    <div class="container">
      <div class="join-club__block">
        <div class="join-club__main">
          <div class="join-club__content">
            <h3 class="join-club__title">Вступите в клуб!</h3>
            <p class="join-club__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt
            </p>
          </div>
          <div class="join-club__button">
            <a class="join-club__btn" href="#?">Вступить</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppJoinClub",
};
</script>

<style lang="scss" scoped></style>
